:root {
    --padding: 1%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 1em; /* must NOT be a percentage value */
    --vertical-margin: 1em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 500px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 10vh;
    
    /* general settings */
    --container-maxwidth: 1100px; 
    
    /* change settings for headings */
    --header-bg-color: transparent;
    --header-padding: 1.5em 0 0;
    
    
    /* change colors for topmenu */
    --topmenu-bg: black;
    --topmenu-color: white;
    --topmenu-li-a-padding: 10px 15px;
}

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.1.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";

/*
@import "/theme/intendit/css/modules/photoswipe.css";
@import "/theme/intendit/css/modules/animate.css";
*/

/* general
---------------------------------------------------------*/

section {
    min-height:50vh;
    padding:1% 0;
}

main {
    margin-top:8vh;
}

.white {
    background-color:white;
}

.gray {
    background-color:#eee;
}

.darkgray {
    background-color:#222;
}
.blue {
    background-color:#2F7398;
}

#menu-icon {
    z-index:100;
}

#menu-icon span {
    border:2px solid black;
}

.page {
    min-height:80vh;
    margin-top:8vh;
}

.page ul {
    list-style-type: none;
    margin:0;
    padding:0;
}


.menu .dropdown .active a, .menu .dropdown li a:hover {
    background:#eee;
    color:black !important;
}
    

/* header
---------------------------------------------------------*/
.startsida header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:100;
    background:var(--header-bg-color);
    text-transform:none;
    padding:var(--header-padding);
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.0);
}

header {
    position:fixed;
    top:0;
    left:0;
    right:0;
    z-index:100;
    background:white;
    text-transform:none;
    padding:0.5em 0;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);

}

header .container {
    padding:0 0.5em;
}

header a {
    color:black;
}

header.change {
    background:white;
    box-shadow: 0 2px 3px 0 rgba(0,0,0,0.2);
    padding:0.5em 0;
}

.startsida header.change .logo img {
    max-width:60%;
}

.startsida header .logo img {
    max-width:100%;
}

header .logo img {
    max-width:60%;
}

.menu li {
    margin:0;
}

.startsida .menu li a {
    color:white;
}

.change .menu li > a {
    color:black;
}

.change .menu li > a:hover, .change .menu .active > a {
    color:white;
}

.menu li a {
    padding:var(--topmenu-li-a-padding);
    color:black;
}

.menu li a:hover, .menu .active > a {
    background: var(--topmenu-bg);
    color:black;
}

.menu .dropdown ul {
    background:#fff;
    z-index:100;
}

.logo {
    padding:0;
}

.logo a {
    font-size:0;
}

/* start-block-wrapper
---------------------------------------------------------*/

.start-bildspel {
    position:relative;
}

.start-bildspel .down {
    position:absolute;
    bottom:5vh;
    right:10vw;
}

.start-bildspel .down i {
    font-weight:300;
}

.startsida .slick-slide, .startsida. slick-slider .startsida .slick-list, .startsida .slick-slider .startsida .slick-track {
    height:100vh;
}

.slick-slider {
    margin-bottom:0;
}

.slider-caption-outer .container {
    height: 100%;
    position: relative;
    text-align: left;
    display: inline-block;
}

.slick-prev, .slick-next {
    width:auto;
    height:auto;
}

.slick-next {
    right:50px;
    z-index:100;
}

.slick-prev {
    left:50px;
    z-index:100;
}

.slick-next:hover, .slick-prev:hover {
    border:none;
}

.slick-next:before, .slick-prev:before {
    font-size:5rem;
}

.slick-dots {
    bottom:-60px;
}

.slick-dots li button:before {
    font-size:0.8rem;
    opacity:1.0;
    color:#fff;
}

.slick-dots li.slick-active button:before {
    color:#f2941c;
}

.slick-dots li {
    border:#D0D0D0 solid 1px;
    border-radius:50%;
    margin:0 2px;
}

.slick-dots li button {
    margin:0;
}

.sliderWrapper {
    position:relative;
    height:100vh;
}

.slider-inner {
    /*position:absolute;
    bottom:8em;*/
    max-width:var(--container-maxwidth);
    width:100%;
    margin:0 auto;
    min-height:87vh;
    color:white;
    text-align:left;
}

.slider-inner h1, .slider-inner h2 {
    text-align:left; 
}

.slider-content {
    max-width:var(--breakpoint-xs);
    width:100%;
    padding:1.5em;
}

.slider-inner h1, .slider-inner h2 { 
    margin:0;
    border-bottom:#B35E00 solid 2px;
    color:black;
    text-transform:uppercase;
}

.articleSliderSmall  {
    cursor:pointer;
    height:auto !important;
    width:auto !important;
    margin-right:3px;
}

.slider-content {
    background:white;
    color:black;
}

/* Startbox
---------------------------------------------------------*/

.startbox {
    text-decoration:none;
    color:#111;
}

.startbox article {
    margin-bottom:1.5em;
}

.pop-img img {
    width:100%;
    border-bottom:#E57917 solid 4px;
}

.startbox-inner {
    padding:0 1.5em;
    display:block;
    position:relative;
}

.block-content {
    color:#111;
}

.block-rub h2 {
    color:#252525;
    font-size:1.5rem;
    font-weight:bold;
    margin:0.5em 0;
}

.boxLink {
    padding-bottom:1.5em;
}



/* block2
---------------------------------------------------------*/
section.start-block-wrappe2:first-child {
    padding:1.5em 0;
    min-height:5vh;
}

section.start-block-wrappe2:first-child .margin > * {
     margin-top: 0;
    margin-bottom: 0;
}

.block2-content {

}

/* block3
---------------------------------------------------------*/
.start-block-wrapper3 .container > h2 {
}

/* block4
---------------------------------------------------------*/

.start-block-wrapper4 h3 {
    margin-top:0;
    font-weight:bold;
}

.start-block-wrapper4 button {
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

textarea {
    min-height:120px;
}

/* block5
---------------------------------------------------------*/
section.start-block-wrapper5 {
    padding:0;
}

/* Footer
---------------------------------------------------------*/

footer {
    color:white;
}

footer a {
    color: white;
    text-decoration:none;
}

footer a:hover {
    color:#F28D1A;
}

.socialamedia a {
    font-size:2.0rem;
    padding-right:0.5em;
}

footer h4 {
    font-size:1.5rem;
    margin-bottom:0.6em;
    color:white;
}


/* other
---------------------------------------------------------*/

.boltform label {
    display: none;
}

.boltform label {
    display: none;
}

input[type="email"], input[type="number"], input[type="search"], input[type="text"], input[type="tel"], input[type="url"], input[type="password"], textarea {
    width: 100%;
}

.button, button, input[type=reset], input[type=submit] {
    margin:0;
    background:#E57917;
    border:#E57917 solid 1px;
    color:white;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

.button:hover, button:hover, input[type=reset]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
}

button[type=submit], input[type=submit] {
    background:#F28D1A;
}

button[type=submit]:hover, input[type=submit]:hover {
    background:#000;
    border:#000 solid 1px;
}

a {
    color:#E57917;
    transition: 0.5s ease;
    -o-transition: 0.5s ease;
    -webkit-transition: 0.5s ease;
}

a:hover {
    color:#282d32;
}

body {
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color:#282d32;
}

.container {
    max-width: var(--container-maxwidth);
    width: 100%;
    padding: 0.5em;

}

img {
    max-width: 100%;
    height: auto;
}

.article-img {
    overflow:hidden;
}

.articleSliderSmall {
    width:auto !important;
}

.articleSliderSmall img {
    margin-right:1px;
    border:#C3C3C3 solid 1px;
}

.adress span {
    display:block;
}


/* Give map a height and it's images no max-width */
.map-canvas {
    height: 50vh;
}

.map-canvas img {
    max-width: none;
}

h1, h2, h3, h4, h5, h6 {
    margin-top:0;
    color:#251408;
}

h1 {
    font-weight:700;
}

.adress span {
    display:block;
}

.contact-info span {
    display:block;
}

.c-form {
    padding-top:0.5em;
    margin-top:0.5em;
}

.ref-img {
    background-size:cover;
    background-repeat:no-repeat;
    min-height:200px;
}

.ref-box h3 {
    margin:0.5em 0;
}

.ref-box .block-content p {
    margin-top:0;
}

hr {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0;
    border: none;
    height: 1px;
    background-color: #eee;
    clear: both;
}
    

/* Make FB widget responsive */
.fb_iframe_widget,
.fb_iframe_widget span,
.fb_iframe_widget iframe[style],
.twitter-timeline {
    width: 100% !important;
}

/* Example media queries*/
@media (max-width: 400px) {}

@media (max-width: 550px) {}

@media (max-width: 750px) {
    .slider-nav  {
        display:none !Important;
    }


}

@media (max-width: var(--breakpoint-menu)) {
    .menu.show {
        background:white;
    }
    .menu {
        background:#eee;
    }
    header nav ul.menu a {
        color:black;
    }
    nav li a:hover, nav .active a {
        background:#eee;
    }
    #menu-icon {
        z-index:100;
        position:absolute;
        top:25px;
        right:5px;
    }
    .startsida .menu li a {
        color:black;
    }
    .menu .active a, .menu li a:hover {
        color:white !important;
    }
    .menu li, .menu li ul, .menu li:hover ul {
        margin:0;
    }

}


@media (min-width: var(--breakpoint-menu)) {
    .slider-caption-outer {
    bottom: 0;
    left: 0;
    overflow: visible;
    position: absolute;
    text-align: center;
    right: 0;
    top: 0;
    }
    .slider-caption {
    position: absolute;
    bottom: 30%;
    width: 100%;
    right: 0;
    text-align: right;
    display: block;
    padding: 0;
    z-index: 3;
    }
}

@media (min-width: var(--breakpoint-xs)) {
    .topmenu {
        margin-top:1.5em;
    }
    .start-block-wrapper4 h3 {
        font-size:2.0rem;
    }
    .slider-inner {
        left: 3em;
    }
}


@media (max-width: var(--breakpoint-m)) {}
@media (max-width: var(--breakpoint-s)) {}
@media (max-width: var(--breakpoint-xs)) {}
